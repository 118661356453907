export const french = {
    "Enter your email and order number to start your return.":"Entrez votre e-mail et numéro de commande pour commencer votre retour.",
    "Email": "E-mail",
    "Email or postal code": "E-mail ou code postal",
    "Postal code": "Code postal",
    "Order number": "Numéro de commande",
    "This is order cannot be found or is not eligible for returns anymore. Please contact our customer service for more info.": "Cette commande est introuvable ou n'est plus éligible aux retours. Veuillez contacter notre service client pour plus d'informations.",
    "Start return or exchange": "Commencer un retour ou un échange",
    "Order number is required": "Le numéro de commande est requis",
    "Email address is required": "L'adresse e-mail est requise",
    "Email address or postal code is required": "L'adresse e-mail ou le code postal est requis",
    "Enter a valid email address": "Entrez une adresse e-mail valide",
    "Need help?": "Besoin d'aide ?",


    "returnable until": "retournable jusqu'à",
    "Choose a product to return or exchange": "Choisissez un produit à retourner ou à échanger",
    "A return for order": "Un retour pour commande",
    "has already been submitted.": "a déjà été soumis.",
    "No products are eligible for return at this time, because your order is not shipped yet.": "Aucun produit n'est éligible au retour pour le moment, car votre commande n'a pas encore été expédiée.",
    "Exchanged": "Échangé",
    "Returned": "Retourné",
    "Exchange": "Échanger",
    "Return": "Retourner",
    "Ineligible": "Inéligible",
    "What do you want to do?": "Que voulez-vous faire ?",
    "Exit": "Quitter",
    "Total additional payment: ": "Paiement supplémentaire total: ",
    "Total refund: ": "Remboursement total: ",
    "Continue with return": "Continuer avec le retour",
    "Continue": "Continuer",
    "product selected": "produit sélectionné",
    "Order": "Commande",


    "Review your return": "Vérifiez votre retour",
    "Summary": "Résumé",
    "Value of returned goods": "Valeur des articles retournés",
    "Value of exchanged goods": "Valeur des articles échangés",
    "Total additional": "Total supplémentaire",
    "Total refund": "Remboursement total",
    "We will refund to the payment method that was used for the original transaction.": "Nous rembourserons sur le moyen de paiement utilisé lors de la transaction initiale.",
    "Product will be sent to": "Le produit sera envoyé à",
    "Leave a comment here...": "Laissez un commentaire ici...",
    "Back": "Retour",
    "Submit return": "Soumettre le retour",
    "You return to us": "Vous nous retournez",
    "You’ll receive": "Vous recevrez",


    "Thanks for your return,": "Merci pour votre retour,",
    "please find the label below.": "vous trouverez l'étiquette ci-dessous.",
    "The address label and return instructions are emailed to": "L'étiquette d'adresse et les instructions de retour ont été envoyées par e-mail à",
    "Download address label": "Télécharger l'étiquette d'adresse",
    "It takes around": "Le traitement de votre retour prend environ",
    "business days to process your return after you hand in your package.": "ours ouvrables après que vous ayez déposé votre colis."
}