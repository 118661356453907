import React, { createContext, useContext} from 'react';
import {useSelector} from "react-redux";
import {english} from "./Languages/english";
import {dutch} from "./Languages/dutch";
import {german} from "./Languages/german";
import {french} from "./Languages/french";

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const shopDetails = useSelector((state) => state.shopDetails);


    const translations = {
        en: english,
        nl: dutch,
        de: german,
        fr: french,
    };

    const t = (key) => translations[shopDetails.language][key] || key;
    return (
        <TranslationContext.Provider value={{ t }}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = () => useContext(TranslationContext);
