import React,{useState, useEffect} from 'react';
import {Card, Button, Row, Col, Container, Form, Alert, Spinner} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {apiService, baseUrl, getToken, shopName} from "../../constent";
import Footer from "../Footer/Footer";
import {useTranslation} from "../TranslationProvider";
const initialState = {
    order_no: "",
    email_zip: ""
};
const initialStateError = {
    order_no: "",
    email_zip: ""
};

const Login = () => {
    const shopDetails = useSelector((state) => state.shopDetails);
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState(initialState);
    const [orderDetailsError, setOrderDetailsError] = useState(initialStateError);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isError, setIsError] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        if(getToken()){
            navigate(`${baseUrl}/${shopName}/order`)
        }
    },[]);

    const onChange = (name, value) => {
        setOrderDetails({...orderDetails, [name]: value})
        setOrderDetailsError({
            ...orderDetailsError,
            [name]: formValidate(name, value)
        });
    };

    const formValidate = (name, value) => {
        switch (name) {
            case "order_no":
                if (!value || value.trim() === "") {
                    return "Order number is required";
                } else {
                    return "";
                }
            case "email_zip":
                if (!value || value.trim() === "") {
                    if(shopDetails.customer_login_type == 1){
                        return "Postal code is required";
                    } else if(shopDetails.customer_login_type == 2){
                        return "Email address is required";
                    } else {
                        return "Email address or postal code is required";
                    }
                } else if(!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)){
                    if(shopDetails.customer_login_type == 2){
                        return "Enter a valid email address";
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };
    const onKeyPress = (event) => {
        if(event.key === "Enter"){
            onLogin()
        }
    }
    const onLogin = async () => {
        let validationErrors = {};
        Object.keys(orderDetails).forEach(name => {
            const error = formValidate(name, orderDetails[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            setOrderDetailsError(validationErrors);
            return;
        }
        setIsLoading(true);

        const payload = {
            ...orderDetails,
            shop:`${shopName}.myshopify.com`
        };
        const response = await apiService.login(payload);
        if(response.status === 200){
            localStorage.setItem("r&eAppToken", response.data);
            localStorage.removeItem("collectedData");
            localStorage.removeItem("returnAndExchangeData");
            setIsLoading(false);
            navigate(`${baseUrl}/${shopName}/order`)
        } else {
            setIsLoading(false);
            setIsActive(true);
            setIsError(true);
            setMessage(response.message);
            toggleActive()
        }
    };

    const toggleActive = () => {
       setTimeout(() => {
           setIsActive(false);
           setIsError(false);
           setMessage('');
       }, 5000)
    };

    return (
        <div className="re-content-area">
          <div className="re-main-content">
              <Container>
                  <Row className={"justify-content-center align-items-center"}>
                      <Col xs={12} md={"auto"}>
                          <Card className={"re-login-card"}>
                              <div className={"position-relative"}>
                                  <Card.Img variant="top" src={shopDetails.bg_image} />
                                  <img className={"re-login-logo"} src={shopDetails.logo}/>
                              </div>
                              <Card.Body>
                                  <Card.Title>{t("Enter your email and order number to start your return.")}</Card.Title>
                                  <Form>
                                      <Form.Group className="mb-2">
                                          <Form.Label>{t("Email")}</Form.Label>
                                          <Form.Control type="text"  placeholder={t(shopDetails.customer_login_type == 1 ? "Postal code" : shopDetails.customer_login_type == 2 ? "Email" : "Email or postal code")} value={orderDetails.email_zip} onChange={(e) => onChange("email_zip", e.target.value)} isInvalid={orderDetailsError.email_zip}/>
                                          <div className="invalid-feedback">{orderDetailsError.email_zip}</div>
                                      </Form.Group>
                                      <Form.Group className="mb-2">
                                          <Form.Label>{t("Order number")}</Form.Label>
                                          <Form.Control type="text" placeholder={t("Order number")} value={orderDetails.order_no} onChange={(e) => onChange("order_no", e.target.value)} onKeyPress={onKeyPress} isInvalid={orderDetailsError.order_no}/>
                                          <div className="invalid-feedback">{orderDetailsError.order_no}</div>
                                      </Form.Group>
                                      {
                                          message ? <Form.Group className="mb-2">
                                              <Alert variant={"danger"}>
                                                  {t("This is order cannot be found or is not eligible for returns anymore. Please contact our customer service for more info.")}
                                              </Alert>
                                          </Form.Group> : ""
                                      }

                                  </Form>
                                  <div className={"mt-4"}>
                                      <Button variant="primary" size='lg' className={"w-100"} disabled={isLoading} onKeyPress={onKeyPress} onClick={onLogin}>
                                          {
                                              isLoading ? <Spinner animation="border" variant="light" size="sm"/> : t("Start return or exchange")
                                          }
                                      </Button>
                                  </div>

                              </Card.Body>
                          </Card>
                      </Col>
                  </Row>
              </Container>
          </div>
           <Footer/>
        </div>

    );
};

export default Login;