export const english = {
    "Enter your email and order number to start your return.": "Enter your email and order number to start your return.",
    "Email": "Email",
    "Email or postal code": "Email or postal code",
    "Postal code": "Postal code",
    "Order number": "Order number",
    "This is order cannot be found or is not eligible for returns anymore. Please contact our customer service for more info.": "This is order cannot be found or is not eligible for returns anymore. Please contact our customer service for more info.",
    "Start return or exchange": "Start return or exchange",
    "Order number is required": "Order number is required",
    "Email address is required": "Email address is required",
    "Email address or postal code is required": "Email address or postal code is required",
    "Enter a valid email address": "Enter a valid email address",
    "Need help?": "Need help?",

    "returnable until": "returnable until",
    "Choose a product to return or exchange" : "Choose a product to return or exchange",
    "A return for order": "A return for order",
    "has already been submitted.": "has already been submitted.",
    "No products are eligible for return at this time, because your order is not shipped yet.": "No products are eligible for return at this time, because your order is not shipped yet.",
    "Exchanged": "Exchanged",
    "Returned": "Returned",
    "Exchange": "Exchange",
    "Return": "Return",
    "Ineligible": "Ineligible",
    "What do you want to do?": "What do you want to do?",
    "Exit": "Exit",
    "Total additional payment: ": "Total additional payment: ",
    "Total refund: ": "Total refund: ",
    "Continue with return": "Continue with return",
    "Continue": "Continue",
    "product selected": "product selected",


    "Review your return": "Review your return",
    "Summary": "Summary",
    "Value of returned goods": "Value of returned goods",
    "Value of exchanged goods": "Value of exchanged goods",
    "Total additional": "Total additional",
    "Total refund": "Total refund",
    "We will refund to the payment method that was used for the original transaction.": "We will refund to the payment method that was used for the original transaction.",
    "Product will be sent to": "Product will be sent to",
    "Leave a comment here...": "Leave a comment here...",
    "Back": "Back",
    "Submit return": "Submit return",
    "You return to us": "You return to us",
    "You’ll receive": "You’ll receive",


    "Thanks for your return,": "Thanks for your return,",
    "please find the label below.": "please find the label below.",
    "The address label and return instructions are emailed to": "The address label and return instructions are emailed to",
    "Download address label": "Download address label",
    "It takes around": "It takes around",
    "business days to process your return after you hand in your package.": "business days to process your return after you hand in your package."
}