import React, {useState, useEffect} from 'react';
import {BrowserRouter, Routes, Route, Navigate,} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.scss"
import {apiService, baseUrl, shopName} from "./constent";
import PrivateRoute from "./Component/Auth/PrivateRoute";
import Login from "./Component/Login/Login";
import {shopDetailsAction} from "./redux/action/shopDetailsAction";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import Order from "./Component/Order/Order";
import Summary from "./Component/Summary/Summary";
import Confirmation from "./Component/Confirmation/Confirmation";
import {TranslationProvider} from "./Component/TranslationProvider";

const App = () => {
    const [isLoading, setIsLoading,] = useState(true);
    const dispatch = useDispatch();
    const shopDetails = useSelector((state) => state.shopDetails);
    useEffect(() => {
        const getSetting = async () => {
            const response = await apiService.getSetting({shop: `${shopName}.myshopify.com`});
            if (response.status === 200) {
                dispatch(shopDetailsAction({...response.data}));
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        };
        getSetting();
    }, []);

    let cssData = "";
    cssData += `.form-control:focus, .form-control:hover{border-color:${shopDetails.primary_color};}`
    cssData += `.btn-primary, .btn-primary:hover, .btn-primary.disabled, .btn-primary:disabled, .btn-primary:focus-visible, .btn-primary:active, .re-product-checkbox .form-check-input:checked, .bg-primary{background-color: ${shopDetails.primary_color}!important; border-color: ${shopDetails.primary_color}!important;}`;
    cssData += `.Polaris-Badge--statusWarning{background-color: ${shopDetails.secondary_color}; color: #ffffff}`;
    cssData += `.Polaris-Badge--statusInfo, .summary-content .Polaris-Banner--statusInfo{background-color: ${shopDetails.primary_color}; color: #ffffff}`;
    cssData += `.re-order .Polaris-Text--headingXl, .re-order .Polaris-TextField__Input, .re-order .Polaris-InlineError, .re-order .Polaris-Button__Text, .re-items-list-title .Polaris-Text--headingXl, .re-items-list-subtitle .Polaris-Text--headingLg, .re-items-details, .re-item-wizard-content-header{font-family: ${shopDetails.primary_font_family}, sans-serif;}`;
    cssData += `.re-footer{font-family: ${shopDetails.secondary_font_family}, sans-serif;}`;
    cssData += `body, .Polaris-Button{font-family: ${shopDetails.primary_font_family}, sans-serif;}`;
    cssData += `.re-item-col .re-item-col-6, .re-review-item-image, .Polaris-Thumbnail--sizeLarge{background-color: ${shopDetails.product_background_color}}`;

    return (
        <div>
            <Helmet
                style={[{
                    "cssText": `${cssData}`
                }]}>
                <link rel="icon" href={shopDetails.favicon}/>
                <title>{shopDetails.app_title}</title>
            </Helmet>
            {
                isLoading ? <div className="loading"></div> :
                    <TranslationProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route element={<PrivateRoute/>}>
                                    {/*<Route path={`${baseUrl}/:id/review`} element={<Review/>}/>*/}
                                    <Route path={`${baseUrl}/:id/order`} element={<Order/>}/>
                                    <Route path={`${baseUrl}/:id/summary`} element={<Summary/>}/>
                                    <Route path={`${baseUrl}/:id/confirmation`} element={<Confirmation/>}/>
                                    <Route path={`${baseUrl}/${shopName}/`}
                                           element={<Navigate to={`${baseUrl}/${shopName}/order`} replace/>}/>
                                </Route>
                                <Route path={`${baseUrl}/:id/login`} element={<Login/>}/>
                                <Route path={`${baseUrl}/${shopName}/`}
                                       element={<Navigate to={`${baseUrl}/${shopName}/login`} replace/>}/>
                            </Routes>
                        </BrowserRouter>
                    </TranslationProvider>
            }
        </div>
    );
}
;

export default App;