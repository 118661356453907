import React, {useEffect, useState, useCallback, Fragment} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom"
import {apiService, baseUrl, currencySymbol, shopName} from "../../constent";
import OrderHeader from "../OrderHeader/OrderHeader";
import {Button,Container, Form, Alert, Spinner, Badge} from 'react-bootstrap';
import Footer from "../Footer/Footer";
import {useTranslation} from "../TranslationProvider";

const Summary = () => {
    const {t} =useTranslation()
    const shopDetails = useSelector((state) => state.shopDetails);
    const navigate = useNavigate();
    const [orderProduct, setOrderProduct] = useState([]);
    const [exchangeProduct, setExchangeProduct] = useState([]);
    const [customerPortalSetting, setCustomerPortalSetting] = useState({});
    const [order, setOrder] = useState({});
    const [setting, setSetting] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [isNewComment, setIsNewComment] = useState(false);
    const [newAddress, setNewAddress] = useState('');
    const [newComment, setNewComment] = useState('');
    const [returnAndExchangeData, setReturnAndExchangeData] = useState({returnCount: 0, exchangeCount: 0, returnPriceTotal: 0, rePriceTotalDiscounted: 0, currencyCode: '', additionalPayment: 0, exPriceTotal: 0, returnPriceTotalCount: 0});
    useEffect(() => {
        const getOrder = async () => {
            setIsLoading(true);
            const response = await apiService.getOrder();
            if (response.status === 200){
                let newLineItems = [];
                let newExchangeItems = [];
                let line_items  = [];
                const collectedData = JSON.parse(localStorage.getItem("collectedData")) || [];
                (response.data.order.line_items || []).map((x, ind) => {
                    if(x.quantity){
                        Array.from(Array(x.quantity)).map((_, i) => {
                            let fulfillment_status = (i + 1) > x.fulfillable_quantity;
                            if(fulfillment_status){
                                line_items.push({...x, quantity: 1})
                            } else {
                                line_items.push({...x, quantity: 1, })
                                // notFulfillmentItems.push({...obj, quantity: 1})
                            }

                        })
                    } else {
                        line_items.push(x)
                    }

                    // return x.quantity > 1 ? Array.from(Array(x.quantity)).map((_, i) => {
                    //     if(i == 0){
                    //         line_items.push({...x, quantity: 1})
                    //     } else {
                    //         line_items.splice(ind + 1, 0, {...x, quantity: 1})
                    //     }
                    // }) : line_items.push({...x, quantity: 1});
                });
                line_items = (line_items || []).map((x, i) => {
                    return {...x, return_exchange_product_id: i + 1, }
                });
                (collectedData || []).map((x) => {
                    let index  = (line_items || []).findIndex((y) => y.id == x.id && y.return_exchange_product_id == x.return_exchange_product_id);
                    if(index !== -1){
                        newLineItems.push({...line_items[index], ...x});
                    }
                    if(x.isExchange && index !== -1){
                        newExchangeItems.push({...line_items[index], ...x});
                    }
                });

                setOrderProduct(newLineItems);
                setExchangeProduct(newExchangeItems);
                const returnAndExchangeData = JSON.parse(localStorage.getItem("returnAndExchangeData")) || {returnCount: 0, exchangeCount: 0, returnPriceTotal: 0, rePriceTotalDiscounted: 0, currencyCode: '', additionalPayment: 0};
                setReturnAndExchangeData(returnAndExchangeData);
                setCustomerPortalSetting(response.data.customer_portal_setting);
                setOrder({...response.data.order, line_items:line_items});
                setSetting(response.data.setting);
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        };
        getOrder();
    }, []);

    const onLogOut = () => {
        localStorage.removeItem("collectedData");
        localStorage.removeItem("r&eAppToken");
        localStorage.removeItem("returnAndExchangeData");
        navigate(`${baseUrl}/${shopName}/login`);
    };

    const clearNewAddress = () => {
        setNewAddress('');
        setIsNewAddress(false)
    };

    const clearNewComment = () => {
        setNewComment('');
        setIsNewComment(false)
    };

    const goBack = () => {
        navigate(`${baseUrl}/${shopName}/order`);
    };
    const onSubmit = async () => {
        setIsSave(true)
        let data = [];
        orderProduct.map((x) => {
            let findVariant = x.variants.find((y) => y.id == x.variant_id)
            let obj = {
                id: Number(x.id.toString().split('-')[0]),
                type: x.isExchange ? 2 : x.isReturn ? 1 : 1, //1=return,2=exchange
                product_id: x.product_id,
                return_exchange_product_id: x.return_exchange_product_id,
                title: x.title,
                variant_id:x.variant_id,
                return_reason_id: x?.optionAndReason?.selectedReasons?.id || null,
                return_reason: {
                    option: x.optionAndReason.label,
                    reason: {
                        label: x?.optionAndReason?.selectedReasons?.label || '',
                        is_additional_info: x?.optionAndReason?.selectedReasons?.is_additional_info || '',
                        additional_info_label: x?.optionAndReason?.selectedReasons?.additional_info_label || '',
                        additional_info_comment: x?.optionAndReason?.selectedReasons?.is_additional_info == '1' ? x?.optionAndReason?.selectedReasons?.additionalInfo : '',
                        sub_reason: {
                            label: x?.optionAndReason?.selectedReasons?.subReason?.label || '',
                            is_additional_info: x?.optionAndReason?.selectedReasons?.subReason?.is_additional_info || '',
                            additional_info_label: x?.optionAndReason?.selectedReasons?.subReason?.additional_info_label || '',
                            additional_info_comment: x?.optionAndReason?.selectedReasons?.subReason?.is_additional_info == '1' ? x?.optionAndReason?.selectedReasons?.subReason?.additionalInfo : '',
                        }
                    }

                },
                exchange_product:  {
                    id: x?.optionAndReason?.selectedProduct?.id ? x?.optionAndReason?.selectedProduct?.id : x.product_id,
                    title: x?.optionAndReason?.selectedProduct?.title ?x?.optionAndReason?.selectedProduct?.title: x.title,
                    variant_id: x?.optionAndReason?.selectedVariant?.id || '',
                    price: x?.optionAndReason?.selectedVariant?.price || '',
                    variant_title: x?.optionAndReason?.selectedVariant?.title || '',
                },
                quantity: 1,
                price: findVariant?.price || 0,
            };
            data.push(obj)
        });
        const payload = {
            id: order.id,
            products: data,
            shipping_address: newAddress,
            customer_note: newComment,
        }
        const response = await apiService.submitRequest(payload);
        if(response.status === 200) {
            setIsSave(false)
            navigate(`${baseUrl}/${shopName}/confirmation`);
            localStorage.setItem("returnExchangeId", response.id)
        } else {
            setIsSave(false)
        }
    };


    return (
        <div className="re-order-content">
            <OrderHeader logo={setting.logo} shopDetails={shopDetails} returnAndExchangeData={returnAndExchangeData} isDisplay={false} onLogOut={onLogOut} onReview={null}/>
            <div className="re-content-area">
                <div className={"re-main-content"}>
                    {
                        isLoading ?
                            <Container>
                                <div className={"re-summary"}>
                                    <div className={"re-summary-container"}>
                                        <div className={"re-card"}>
                                            <div className={"re-loading"}><Spinner/></div>
                                        </div>
                                    </div>
                                </div>
                            </Container> :
                            <Container>
                                <div className={"re-summary"}>
                                    <div className={"re-summary-container"}>
                                        <div className={"re-summary-col"}>
                                            <div className={"re-summary-content"}>
                                                <div className={"re-summary-header"}>
                                                    <div className="re-order-no"><p>{t("Order")} #{order?.order_number}</p></div>
                                                    <div className="re-order-title"><h2>{t("Review your return")}</h2></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"re-summary-container"}>
                                        <div className={"re-summary-col"}>
                                            <div className={"re-summary-content"}>
                                                <div className={"re-summary-title"}>
                                                    <h6>{t("Summary")}</h6>
                                                </div>
                                                <div className={"re-summary-body"}>
                                                    <ul>
                                                        <li>
                                                            <span>{t("Value of returned goods")}</span>
                                                            <span>{`${shopDetails.currency ? currencySymbol[shopDetails.currency]:''}
                                                            
                                                            ${returnAndExchangeData?.returnProductPriceTotal ? parseFloat(returnAndExchangeData.returnProductPriceTotal).toFixed(2):""}`}</span>
                                                        </li>
                                                        {
                                                            returnAndExchangeData?.exchangeProductPriceTotal > 0 ?
                                                                <li>
                                                                    <span>{t("Value of exchanged goods")}</span>
                                                                    <span>{`${shopDetails.currency ? currencySymbol[shopDetails.currency]: ''}${returnAndExchangeData?.exchangeProductPriceTotal ? parseFloat(returnAndExchangeData.exchangeProductPriceTotal).toFixed(2):''}`}</span>
                                                                </li> : ""
                                                        }

                                                        <li>
                                                            <span>{parseFloat(returnAndExchangeData.exchangeProductPriceTotal) - parseFloat(returnAndExchangeData.returnProductPriceTotal) > 0 ? setting.allow_max_expensive == 1 ? t("Total additional") : '' : t("Total refund")}</span>
                                                            <span className={parseFloat(returnAndExchangeData.exchangeProductPriceTotal) - parseFloat(returnAndExchangeData.returnProductPriceTotal) > 0 ? setting.allow_max_expensive == 1 ? "additional-payment" : '' : 'refund-payment'}>{`${shopDetails.currency ? currencySymbol[shopDetails.currency]:""}${
                                                                Math.abs((parseFloat(returnAndExchangeData?.exchangeProductPriceTotal || 0) - parseFloat(returnAndExchangeData?.returnProductPriceTotal || 0))).toFixed(2)}`}</span>
                                                        </li>
                                                        <li>
                                                            <Alert  variant={"primary"}>
                                                                {t("We will refund to the payment method that was used for the original transaction.")}
                                                            </Alert>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={"re-summary-title"}>
                                                    <h6>{t("Product will be sent to")}</h6>
                                                </div>
                                                <div className={"re-summary-body re-summary-address"}>

                                                    {
                                                        order?.shipping_address?.name ? <p>{order?.shipping_address?.name}</p> :  ""
                                                    }
                                                    {
                                                        order?.shipping_address?.address1 ? <p>{order?.shipping_address?.address1}</p>: ""
                                                    }
                                                    {
                                                        order?.shipping_address?.address2 ? <p>{order?.shipping_address?.address2}</p> : ""
                                                    }
                                                    <p>
                                                        {order?.shipping_address?.zip ? <span>{order?.shipping_address?.zip} </span>  : ""}
                                                        {order?.shipping_address?.city ? <span>{order?.shipping_address?.city} </span>  : ""}
                                                        {order?.shipping_address?.province ? <span>{order?.shipping_address?.province} </span> : ""}
                                                    </p>
                                                    {
                                                        order?.shipping_address?.country ? <p>{order?.shipping_address?.country}</p> :""
                                                    }
                                                </div>
                                                <div className={"re-summary-body"}>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Control as="textarea" rows={3} onChange={(e) => setNewComment(e.target.value)} value={newComment} placeholder={t("Leave a comment here...")}/>
                                                        </Form.Group>
                                                    </Form>
                                                    <div className={"re-summary-action"}>
                                                        <Button variant={"light"} size={"lg"} onClick={goBack}>{t("Back")}</Button>
                                                        <Button variant={"primary"} size={"lg"} className={"w-100"} onClick={isSave ? null : onSubmit}>{isSave ? <Spinner size={"sm"}/> : t("Submit return")}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"re-summary-product-col"}>
                                            <div className={"re-summary-product-content"}>
                                                {
                                                    orderProduct.length ?   <div className={"re-summary-title"}>
                                                        <h6>{t("You return to us")}</h6>
                                                    </div> : ""
                                                }
                                                {
                                                    orderProduct.length ?  <div className="re-summary-product">
                                                        <ul>
                                                            {
                                                                orderProduct.map((record, i) => {
                                                                    let currencyCode = record?.price_set?.presentment_money?.currency_code;
                                                                    let finalPrice = null;
                                                                    if(record.discount_allocations.length > 0){
                                                                        const discountAllocationPrice = record.discount_allocations[0].amount;
                                                                        finalPrice = parseFloat(record.price) - parseFloat(discountAllocationPrice)
                                                                    }
                                                                    const findVariant = (record.variants || []).find((y) => y.id === record.variant_id);
                                                                    const findImage = (record.images || []).find((y) => y.id === findVariant.image_id);
                                                                    return (
                                                                        <li key={i}>
                                                                            <div className="re-product-img">
                                                                                <img src={findImage?.src ? findImage?.src : record.image} />
                                                                            </div>
                                                                            <div className={"re-product-details"}>
                                                                                <div className={"re-product-content"}>
                                                                                    <h6>{ record?.title}</h6>
                                                                                    {
                                                                                        record.variant_title ?  <p className={"re-product-variant"}>
                                                                                            {(record?.options || []).map((op, opInd) => {return(
                                                                                                <span key={opInd}>{op.name} {record?.options.length === opInd +1 ? ": ": "/ "}</span>
                                                                                            )})}
                                                                                            {record.variant_title}
                                                                                        </p> : ""
                                                                                    }

                                                                                    <p className={"re-product-price"}>
                                                                                        {finalPrice !== null ? <React.Fragment>
                                                                                                <span className="re-item-price-discount">{currencySymbol[shopDetails.currency]}{parseFloat(record.price).toFixed(2)}</span>
                                                                                                &nbsp;
                                                                                                <span>{currencySymbol[shopDetails.currency]}{finalPrice.toFixed(2)}</span>
                                                                                            </React.Fragment> :
                                                                                            <span>{currencySymbol[shopDetails.currency]}{parseFloat(record.price).toFixed(2)}</span>
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className={"re-product-status"}>
                                                                                    <Badge bg="primary">{record?.isExchange ? t("Exchange") : record?.isReturn ? t("Return") :  ""}</Badge>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>

                                                    </div> : ""
                                                }
                                                {
                                                    exchangeProduct.length ? <div className={"re-summary-title"}>
                                                        <h6>{t("You’ll receive")}</h6>
                                                    </div> : ''
                                                }

                                                {exchangeProduct.length ? <div className="re-summary-product">
                                                    <ul>
                                                        {
                                                            exchangeProduct.map((record, i) => {
                                                                let currencyCode = record?.price_set?.presentment_money?.currency_code;
                                                                let finalPrice = null;
                                                                // if(record.discount_allocations.length > 0){
                                                                //     const discountAllocationPrice = record.discount_allocations[0].amount;
                                                                //     finalPrice = parseFloat(record.optionAndReason.selectedVariant.price) - parseFloat(discountAllocationPrice)
                                                                // }
                                                                let findVariant = {};
                                                                let findImage = {};
                                                                if(record?.optionAndReason?.selectedProduct){
                                                                    findVariant = (record?.optionAndReason?.selectedProduct.variants || []).find((y) => y.id === record.optionAndReason.selectedVariant.id);
                                                                    findImage = findVariant?.image_id ?(record?.optionAndReason?.selectedProduct.images || []).find((y) => y.id === findVariant.image_id) : record?.optionAndReason?.selectedProduct?.image;
                                                                } else {
                                                                    findVariant = (record.variants || []).find((y) => y.id === record.optionAndReason.selectedVariant.id);
                                                                    findImage = (record.images || []).find((y) => y.id === findVariant.image_id);
                                                                }
                                                                return (

                                                                    <li key={i}>
                                                                        <div className="re-product-img">
                                                                            <img src={findImage?.src ? findImage?.src : record.image} />
                                                                        </div>
                                                                        <div className={"re-product-details"}>
                                                                            <div className={"re-product-content"}>
                                                                                <h6>{record?.optionAndReason?.selectedProduct ? record?.optionAndReason?.selectedProduct.title : record.title}</h6>
                                                                                {
                                                                                    record.variant_title ?  <p className={"re-product-variant"}>
                                                                                        {(record?.options || []).map((op, opInd) => {return(
                                                                                            <span key={opInd}>{op.name} {record?.options.length === opInd +1 ? ": ": "/ "}</span>
                                                                                        )})} {record.optionAndReason.selectedVariant.title}
                                                                                    </p> : ""
                                                                                }

                                                                                <p className={"re-product-price"}>
                                                                                    {finalPrice !== null ? <React.Fragment>
                                                                                            <span className="re-item-price-discount">{currencySymbol[shopDetails.currency]}{parseFloat(record.optionAndReason.selectedVariant.price).toFixed(2)}</span>
                                                                                            &nbsp;
                                                                                            <span>{currencySymbol[shopDetails.currency]}{finalPrice.toFixed(2)}</span>
                                                                                        </React.Fragment> :
                                                                                        <span>{currencySymbol[shopDetails.currency]}{parseFloat(record.optionAndReason.selectedVariant.price).toFixed(2)}</span>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div> : ''}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Container>
                    }
                </div>

                <Footer/>
            </div>

        </div>
    );
};

export default Summary;