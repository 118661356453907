export const dutch = {
    "Enter your email and order number to start your return.": "Voer je e-mailadres en ordernummer in om je retour te starten.",
    "Email": "Email",
    "Email or postal code": "Email of postcode",
    "Postal code": "Postcode",
    "Order number": "Ordernummer",
    "This is order cannot be found or is not eligible for returns anymore. Please contact our customer service for more info.":"Deze bestelling kan niet worden gevonden of komt niet meer in aanmerking voor retourneren. Neem contact op met onze klantenservice voor meer informatie.",
    "Start return or exchange": "Start retour of omruiling",
    "Order number is required":"Ordernummer is vereist",
    "Email address is required": "E-mailadres is vereist",
    "Email address or postal code is required": "E-mailadres of postcode is vereist",
    "Enter a valid email address": "Voer een geldig e-mailadres in",
    "Need help?": "Hulp nodig?",


    "returnable until": "retourneerbaar tot",
    "Choose a product to return or exchange": "Kies een product om te retourneren of om te ruilen",
    "A return for order": "een retour voor bestelling",
    "has already been submitted.": "is al ingediend",
    "No products are eligible for return at this time, because your order is not shipped yet.": "Er zijn op dit moment geen producten in aanmerking voor retournering, omdat uw bestelling nog niet is verzonden.",
    "Exchanged": "Uitgewisseld",
    "Returned": "Teruggekeerd",
    "Exchange": "Uitwisseling",
    "Return": "Terugkeren",
    "Ineligible": "Onbevoegd",
    "What do you want to do?": "Wat wil je doen?",
    "Exit": "Afsluiten",
    "Total additional payment: ": "Totaal extra betaling: ",
    "Total refund: ": "Totaal terugbetaling: ",
    "Continue with return": "Doorgaan met retourneren",
    "Continue": "Voortzetten",
    "product selected": "product geselecteerd",
    "Order": "Volgorde",


    "Review your return": "Controleer je retour",
    "Summary": "Samenvatting",
    "Value of returned goods": "Waarde van geretourneerde producten",
    "Value of exchanged goods": "Waarde van geruilde producten",
    "Total additional": "Totaal extra kosten",
    "Total refund": "Totaal terugbetaling",
    "We will refund to the payment method that was used for the original transaction.": "We storten het bedrag terug op de betaalmethode die werd gebruikt bij de oorspronkelijke transactie.",
    "Product will be sent to": "Product wordt verzonden naar",
    "Leave a comment here...": "Laat een opmerking achter...",
    "Back": "Terug",
    "Submit return": "Retour indienen",
    "You return to us": "Je retourneert aan ons",
    "You’ll receive": "Je ontvangt",

    "Thanks for your return,": "Bedankt voor je retour,",
    "please find the label below.": "je vindt het label hieronder.",
    "The address label and return instructions are emailed to": "Het adreslabel en de retourinstructies zijn verzonden naar",
    "Download address label": "Download adreslabel",
    "It takes around": "Het duurt ongeveer",
    "business days to process your return after you hand in your package.": "werkdagen om je retour te verwerken nadat je het pakket hebt afgegeven."

}