import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "../TranslationProvider";

const Footer = () => {
    const shopDetails = useSelector((state) => state.shopDetails);
    const {t} = useTranslation()
    return (
        <div className="re-footer-content">
            <span>{t("Need help?")} <strong>{shopDetails?.support_email}</strong></span>
        </div>
    );
};

export default Footer;