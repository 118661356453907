export const german = {
    "Enter your email and order number to start your return.": "Geben Sie Ihre E-Mail-Adresse und Bestellnummer ein, um Ihre Rücksendung zu starten.",
    "Email": "E-Mail",
    "Email or postal code": "E-Mail oder Postleitzahl",
    "Postal code": "Postleitzahl",
    "Order number": "Bestellnummer",
    "This is order cannot be found or is not eligible for returns anymore. Please contact our customer service for more info.":"Diese Bestellung kann nicht gefunden werden oder ist nicht mehr für Rücksendungen berechtigt. Bitte kontaktieren Sie unseren Kundenservice für weitere Informationen.",
    "Start return or exchange": "Rücksendung oder Umtausch starten",
    "Order number is required": "Bestellnummer ist erforderlich",
    "Email address is required": "E-Mail-Adresse ist erforderlich",
    "Email address or postal code is required": "E-Mail-Adresse oder Postleitzahl ist erforderlich",
    "Enter a valid email address": "Geben Sie eine gültige E-Mail-Adresse ein",
    "Need help?": "Brauchen Sie Hilfe?",


    "returnable until": "rücksendbar bis",
    "Choose a product to return or exchange": "Wählen Sie ein Produkt zum Zurückgeben oder Umtauschen aus",
    "A return for order": "Eine Rückgabe zur Bestellung",
    "has already been submitted.": "wurde bereits eingereicht.",
    "No products are eligible for return at this time, because your order is not shipped yet.": "Derzeit können keine Produkte zurückgegeben werden, da Ihre Bestellung noch nicht versandt wurde.",
    "Exchanged": "ausgetauscht",
    "Returned": "zurückgegeben",
    "Exchange": "Austausch",
    "Return": "Rückkehr",
    "Ineligible": "nicht berechtigt",
    "What do you want to do?": "Was möchten Sie tun?",
    "Exit": "Beenden",
    "Total additional payment: ": "Gesamtzuschlag: ",
    "Total refund: ": "Gesamterstattung: ",
    "Continue with return": "Mit Rücksendung fortfahren",
    "Continue": "fortfahren",
    "product selected": "produkt ausgewählt",
    "Order": "Bestell",


    "Review your return": "Überprüfen Sie Ihre Rücksendung",
    "Summary": "Zusammenfassung",
    "Value of returned goods": "Wert der zurückgesandten Ware",
    "Value of exchanged goods": "Wert der umgetauschten Ware",
    "Total additional": "Gesamtkosten zusätzlich",
    "Total refund": "Gesamterstattung",
    "We will refund to the payment method that was used for the original transaction.": "Wir erstatten den Betrag auf die Zahlungsmethode zurück, die bei der ursprünglichen Transaktion verwendet wurde.",
    "Product will be sent to": "Produkt wird gesendet an",
    "Leave a comment here...": "Hinterlassen Sie hier einen Kommentar...",
    "Back": "Zurück",
    "Submit return": "Rücksendung abschicken",
    "You return to us": "Sie senden an uns zurück",
    "You’ll receive": "Sie erhalten",


    "Thanks for your return,": "Vielen Dank für Ihre Rücksendung,",
    "please find the label below.": "das Etikett finden Sie unten.",
    "The address label and return instructions are emailed to": "Das Adressetikett und die Rücksendeanweisungen wurden an folgende E-Mail-Adresse gesendet",
    "Download address label": "Adressetikett herunterladen",
    "It takes around": "Es dauert etwa",
    "business days to process your return after you hand in your package.": "Werktage, um Ihre Rücksendung zu bearbeiten, nachdem Sie das Paket abgegeben haben.",
}